const pristineConfig = {
    screens: {
      sm: '576px',
      md: '768px',
      lg: '992px',
      xl: '1200px',
      xl2: '1400px',
      xl3: '1600px',
    },
    gutters: {
      base: '15px',
      md: '30px',
      lg: '50px',
      xl: '70px',
    },
    colors: {
      primary: '#0C58A2',
      'primary-light': '#FF2F70B1',
      secondary: '#DEDC00',
      'secondary-light': '#dedc0080',
      greydark: '#585858',
      grey: '#868686',
      greylight: '#d6d6d6',
      greylightest: '#E6E9ED',
      offWhite: '#F0F2F4',
      success: '#608D61',
      danger: '#bf0000'
    },
    spacing: {
      xl3: '70px',
      xl2: '50px',
      xl: '40px',
      lg: '30px',
      base: '25px',
      md: '20px',
      sm: '15px',
      xs: '10px',
      xs2: '5px',
    },
    backgroundColor: {},
    backgroundPosition: {},
    backgroundSize: {},
    borderColor: {},
    borderRadius: {},
    borderWidth: {},
    boxShadow: {},
    container: {},
    cursor: {},
    fill: {},
    flex: {},
    flexGrow: {},
    flexShrink: {},
    fontFamily: {},
    fontSize: {
      xs: '10px',
      sm: '14px',
      base: '16px',
      md: '20px',
      lg: '26px',
      xl: '40px',
    },
    fontWeight: {},
    height: {
      input: '45px',
      navigationResponsive: '90px',
    },
    inset: {},
    letterSpacing: {
      base: '0.4px',
      md: '0.5px',
      lg: '0.7px',
      xl: '1.05px',
    },
    lineHeight: {},
    listStyleType: {},
    margin: {},
    maxHeight: {},
    maxWidth: {},
    minHeight: {
      xl: '250px',
    },
    minWidth: {},
    objectPosition: {},
    opacity: {},
    order: {},
    padding: {},
    stroke: {},
    textColor: {},
    width: {
      navigation: '350px',
    },
    zIndex: {},
};

module.exports = pristineConfig;
