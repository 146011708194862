import Ready from '@/utils/ready';
// @ts-ignore
import * as Swiper from 'swiper/js/swiper.js';

const selector = '[data-ref="cardSlider"]';

class CardSlider {
  el: any;
  instance: any = null;
  nav: any;
  navItems: any;

  constructor(el: any, options = {}) {
    this.el = el.querySelector('.swiper-container');
    this.nav = this.el.querySelector('.cardSlider__nav__title');
    this.navItems = this.nav.querySelectorAll('.text');
    this.instantiate(this.el, options);
    this.addEventListeners();
  }

  private instantiate(el: any, options: any) {
    this.instance = new Swiper.default(el, {
      ...options,
    });
    let index = this.instance.realIndex;
    if (this.navItems.length != 0) {
      this.navItems[index].style.display = "block";
    }
  }

  private addEventListeners() {
    this.instance.on('slideChange', () => {
      let index = this.instance.realIndex;
      for (let i = 0; i < this.navItems.length; i++) {
        this.navItems[i].style.display = "none";
      }
      this.navItems[index].style.display = "block";
    });
  }
}

(() => {
  Ready.watch(selector, (element: any) => {
    let options = {
      navigation: {
        nextEl: '.cardSlider__nav__right',
        prevEl: '.cardSlider__nav__left',
      },
    };
    if (element.dataset.options) {
      options = JSON.parse(element.dataset.options);
    }
    return new CardSlider(element, {
      ...options,
    });
  });
})();
