import Ready from '@/utils/ready';
import { gsap, Power4 } from 'gsap';
import { Draggable } from 'gsap/Draggable';

gsap.registerPlugin(Draggable);
class History {
  private el: HTMLElement;
  private elStyle: any;
  private elOffsetLeft: number;
  private isRunning: boolean = false;
  private wrapperEl: HTMLElement | null;
  private readonly draggableEl: HTMLElement | null;
  private slides: any[] = [];
  private draggable: any;
  private pagination: any;
  private paginationItems: any[] = [];
  private isMobile: boolean = false;

  constructor(element: HTMLElement) {
    this.el = element;
    this.elStyle = window.getComputedStyle(this.el);
    this.elOffsetLeft = parseInt(this.elStyle.paddingLeft);

    this.slides = Array.from(this.el.querySelectorAll('.history__slide'));
    this.wrapperEl = this.el.querySelector('.history__wrapper');
    this.draggableEl = this.el.querySelector('[data-draggable]');
    this.pagination = this.el.querySelector('.history__pagination');
    if (this.pagination) {
      this.paginationItems = Array.from(
        this.pagination.querySelectorAll('.history__pagination__date__item'),
      );
    }
    if (window.innerWidth <= 600) {
      this.isMobile = true;
    }

    if (this.draggableEl) {
      this.addEvents();
      this.initDraggable(this.draggableEl);
    }
  }

  private initDraggable(target: HTMLElement | string) {
    let minX: number = -this.slides
      .map((slide: any, index: number) => slide.offsetWidth)
      .reduce((acc: number, current: number, index: number) => {
        if (index + 1 === this.slides.length) {
          if (this.isMobile || current > (this as any).wrapperEl.offsetWidth) {
            return acc + current - (this as any).wrapperEl.offsetWidth;
          }
          return acc;
        }
        return acc + current;
      });
    this.draggable = Draggable.create(target, {
      inertia: true,
      type: 'x',
      lockAxis: true,
      edgeResistance: 0.65,
      endRotation: 5,
      autoScroll: 0,
      tween: true,
      bounds: {
        minY: 0,
        maxY: 0,
        minX: minX,
        maxX: 0,
      },
      // onThrowUpdate: function(){
      //   let vy = 50 - (this.x / 40)
      //   TweenMax.to(".Si", .6, {
      //     backgroundPositionY: `${vy}%`,
      //     '-webkit-background-position-y': `${vy}%`
      //   })
      // },
      onDragStart: () => {
        this.removeArrow();
      },
      onDragEnd: () => {
        this.setActivePagination();
      },
    });
  }

  private addEvents() {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 600) {
        this.isMobile = true;
      }
      this.draggable.update();
    });
    this.paginationItems.forEach((paginationItem: any, index: number) => {
      const currentSlide = this.slides[index];
      paginationItem.addEventListener('click', (e: Event) => {
        this.removeArrow();
        if (!this.isRunning) {
          this.animate(paginationItem, currentSlide);
        }
      });
    });
  }

  private animate(paginationItem: any, currentSlide: any) {
    const slideToX = (this as any).wrapperEl.getBoundingClientRect().x - currentSlide.getBoundingClientRect().x;
    this.isRunning = true;
    this.paginationItems.forEach((item: any) => {
      if (item === paginationItem) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });
    this.activeSlide(currentSlide);
    (gsap as any).to(this.draggableEl, .8, {
      x: `+=${slideToX}`,
      ease: Power4.easeInOut,
      onComplete: () => {
        this.isRunning = false;
      },
    });
  }

  private removeArrow() {
    let arrow = document.querySelector(".history__arrow") as HTMLElement;
    if(arrow) {
      arrow.style.display = "none";
    }
  }

  private setActivePagination() {
    this.slides.forEach((slide: any, index: number) => {
      const correspondingPagination = this.paginationItems[index];
      const wrapperXvalue = (this as any).wrapperEl.getBoundingClientRect().x;
      const slideStartXvalue = slide.getBoundingClientRect().x;
      const slideEndXvalue = slide.getBoundingClientRect().right;
      if (
        slideStartXvalue <= wrapperXvalue &&
        slideEndXvalue >= wrapperXvalue
      ) {
        slide.classList.add('active');
        correspondingPagination.classList.add('active');
      } else {
        slide.classList.remove('active');
        correspondingPagination.classList.remove('active');
      }
    });
  }

  private activeSlide(currentSlide: any) {
    this.slides.forEach((slide: any) => {
      if (slide === currentSlide) {
        slide.classList.add('active');
      } else {
        slide.classList.remove('active');
      }
    });
  }
}

Ready.watch('[data-ref="history"]', (element: HTMLElement) => {
  new History(element);
})

export default History;
