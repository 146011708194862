document.documentElement.style.setProperty(
  '--scrollbar-width',
  window.innerWidth - document.documentElement.clientWidth + 'px',
);

/* Auto Imports */
const srcContext = require.context(
  '@',
  true,
  /\.(runtime|asset|style)\.(.*?)$/,
);
srcContext.keys().forEach(srcContext);

const templatesContext = require.context(
  '@root/templates',
  true,
  /\.(runtime|asset|style)\.(.*?)$/,
);
templatesContext.keys().forEach(templatesContext);
/* Add polyfills from the polyfills folder (order matters) */
import '@/polyfills/WeakMap';
import '@/polyfills/MutationObserver';
import '@/polyfills/ObjectFit';

import 'alpinejs/dist/alpine-ie11';
import ElementQueries from 'css-element-queries/src/ElementQueries';

ElementQueries.listen();

/* Import Pristine Config (for HRM)  */
import '@config/pristine.config.js';

/* Unicons: https://iconscout.com/unicons */
import '@iconscout/unicons/css/unicons.css';

/* Fontisto */
import 'fontisto';

/* Import Styles */
import '@/styles/main.scss';
