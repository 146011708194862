import Ready from '@/utils/ready';
import { throttle } from 'lodash';
// @ts-ignore
import * as Swiper from 'swiper/js/swiper.js';

const selector = '[data-ref="slider"]';

class Slider {
  el: any;
  instance: any = null;

  constructor(el: any, options = {}) {
    this.el = el.querySelector('.swiper-container');
    this.instantiate(this.el, options);
    this.checkWidth();
    window.addEventListener('resize', throttle(this.checkWidth.bind(this), 1000));
  }

  private instantiate(el: any, options: any) {
    this.instance = new Swiper.default(el, {
      ...options,
    });
  }

  private checkWidth() {
    if (window.innerWidth >= 1200 && this.instance.slides.length > 4) {
      this.el.classList.add('hasNavigation');
    } else if (window.innerWidth >= 768 && window.innerWidth < 1200 && this.instance.slides.length > 2) {
      this.el.classList.add('hasNavigation');
    } else if (window.innerWidth < 768 && this.instance.slides.length > 1) {
      this.el.classList.add('hasNavigation');
    } else {
      this.el.classList.remove('hasNavigation');
    }
    this.instance.update();
  }
}

(() => {
  Ready.watch(selector, (element: any) => {
    let options = {};
    if (element.dataset.options) {
      options = JSON.parse(element.dataset.options);
    }
    return new Slider(element, {
      navigation: {
        prevEl: '[data-ref="sliderNavPrev"]',
        nextEl: '[data-ref="sliderNavNext"]',
        disabledClass: 'hidden',
        hiddenClass: 'hidden',
      },
      ...options,
    });
  });
})();
