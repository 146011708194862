import Ready from '@/utils/ready';

const selector = '[data-ref="cookies"]';

class Cookies {
  el: any;
  confirmEl: any;
  refuseEl: any;
  confirmPath: any;
  refusePath: any;

  constructor(el: any) {
    this.el = el;
    this.confirmEl = this.el.querySelector('[data-ref="cookiesConfirm"]');
    this.refuseEl = this.el.querySelector('[data-ref="cookiesRefuse"]');
    this.confirmPath = this.el.dataset.linkAccept;
    this.refusePath = this.el.dataset.linkRefuse;
    this.addEventListeners();
  }

  private addEventListeners() {
    this.confirmEl.addEventListener('click', () => {
      this.el.style.display = 'none';
      const request = new XMLHttpRequest();
      request.open('POST', this.confirmPath, true);
      request.send();
    });
    this.refuseEl.addEventListener('click', () => {
      this.el.style.display = 'none';
      const request = new XMLHttpRequest();
      request.open('POST', this.refusePath, true);
      request.send();
    });
  }
}

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    new Cookies(element);
  });
})();
