import Ready from "@/utils/ready";
import objectFitImages from 'object-fit-images';
const selector = '[data-ref="objectFit"]';

(() => {
  Ready.watch(selector, element => {
    if (element) {
      objectFitImages(element);
    }
  });
})();
