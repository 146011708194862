import '@/assets/logo-acaplast.png';
import '@/assets/logo-acaplast-white.svg';
import '@/assets/logo-acaplast-white-placeholder.jpg';
import '@/assets/placeholder.png';

import '@/assets/favicon.png';

import '@/assets/map-sites.png';
import '@/assets/map-sites2.png';

import '@/assets/homepage_video.asset.mp4';
import '@/assets/homepage_video.asset.webm';

import '@/assets/button-primary.svg';
import '@/assets/button-primary-plus.svg';
import '@/assets/button-secondary.svg';
import '@/assets/button-secondary-plus.svg';
import '@/assets/losange.png';


import '@/assets/diamond-grey.svg';
import '@/assets/diamond-primary.svg';

import '@/assets/qualite_acaplast_01.jpg';
import '@/assets/qualite_acaplast_02.jpg';
import '@/assets/qualite_acaplast_03.jpg';
import '@/assets/qualite_acaplast_04.jpg';
import '@/assets/qualite_acaplast_05.jpg';
import '@/assets/logo-iatf.png';
import '@/assets/logo-iso.png';


// Sites
import '@/assets/sites/image-site-acap82.jpg';
import '@/assets/sites/image-site-ai2p.jpg';
import '@/assets/sites/image-site-france.jpg';
import '@/assets/sites/image-site-maroc.jpg';
import '@/assets/sites/image-site-marsac.jpg';
import '@/assets/sites/image-site-mayenne.jpg';
import '@/assets/sites/image-site-pbt.jpg';
import '@/assets/sites/image-site-tunisie.jpg';
import '@/assets/sites/image-site-tupi.jpg';

import '@/assets/sites/logo-site-acap82.png';
import '@/assets/sites/logo-site-ai2p.png';
import '@/assets/sites/logo-site-france.png';
import '@/assets/sites/logo-site-maroc.png';
import '@/assets/sites/logo-site-mayenne.png';
import '@/assets/sites/logo-site-pbt.png';
import '@/assets/sites/logo-site-tunisie.png';
import '@/assets/sites/logo-site-tupi.png';

import '@/assets/qui_sommes_nous.jpg';
import '@/assets/rejoignez_nous.jpg';
import '@/assets/what_matters_to_us.jpg';

import '@/assets/casque.svg';
import '@/assets/usine.svg';
import '@/assets/family.png';
import '@/assets/voiture.svg';

import '@/assets/hero_rh.jpg';
import '@/assets/photo.jpg';
